/* Product Card! */
.container {
  /* Shadow the Card */
  /*  box-shadow: 0 15px 30px 1px grey; */
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.90);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 50px;
  height: calc(250px * var(--expansion-factor));
  width: calc(450px * var(--expansion-factor));
  /* remove to display center */
  display: inline-block;
  position: relative;
  z-index: 2;
}

.product-details {
  position: relative;
  text-align: left;
  overflow: hidden;
  height: 100%;
  width: 50%;
  display: inline-block;
}

.product-details-date {
  font-size: calc(12px * var(--expansion-factor));
  color: #374254;
  margin: 5px 10px;
  opacity: 0.8;
  text-align: right;
}

.container .product-details h1 {
  font-family: 'Bebas Neue', cursive;
  display: block;
  position: relative;
  font-size: calc(30px * var(--expansion-factor));
  color: #344055;
  margin: 0;
}

.container .product-details h1:before {
  position: absolute;
  content: '';
  right: 0%;
  top: 0%;
  transform: translate(25px, -15px);
  font-family: 'Bree Serif', serif;
  display: inline-block;
  background: #ffe6e6;
  border-radius: 5px;
  font-size: calc(14px * var(--expansion-factor));
  padding: 5px;
  color: white;
  margin: 0;
  animation: chan-sh 6s ease infinite;
}

div.product-details a {
  position: relative;
  display: block;
  padding-left: 20px;
}

.hint-star {
  display: inline-block;
  margin-left: 0.5em;
  color: gold;
  width: 50%;
  font-size: calc(15px * var(--expansion-factor));
}

.hint-star i {
  text-shadow: 2px 0 0 #0002, -2px 0 0 #0002, 0 2px 0 #0002, 0 -2px 0 #0002, 1px 1px #0002, -1px -1px 0 #0002, 1px -1px 0 #0002, -1px 1px 0 #0002;
}

.badge-container img {
  width: auto;
  height: auto !important;
}

.badge-img {
  display: inline-flex;
  height: calc(35px * var(--expansion-factor));
  margin-right: 10px;
  width: calc(35px * var(--expansion-factor));
  cursor: pointer;
}

.badge-container {
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 1px;
}

#container .product-details>p {
  font-family: 'EB Garamond', serif;
  text-align: center;
  font-size: calc(18px * var(--expansion-factor));
  color: #7d7d7d;
  padding-left: 30px;
}

.control {
  display: none;
  position: absolute;
  bottom: 20%;
  left: 22.8%;

}

.btn .price,
.shopping-cart {
  background: #333;
  border: 0;
  margin: 0;
}

.btn .price {
  transform: translateX(-10%);
  padding-right: 15px;
}

.btn .shopping-cart {
  transform: translateX(-100%);
  position: absolute;
  background: #333;
  z-index: 1;
  left: 0;
  top: 0;
}

.btn .buy {
  z-index: 3;
  font-weight: bolder;
}

.btn:hover .price {
  transform: translateX(-110%);
}

.btn:hover .shopping-cart {
  transform: translateX(0%);
}

.product-image {
  transition: all 0.3s ease-out;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 50%;
  display: inline-block;
}

.container img {
  height: 100%;
}

.info, .info-commit {
  background: rgba(27, 26, 26, .9);
  transition: all .3s ease-out;
  transform: translateX(-100%);
  position: absolute;
  line-height: 1.8;
  text-align: left;
  font-size: calc(135%* var(--expansion-factor));
  cursor: no-drop;
  color: #fff;
  height: 100%;
  width: 100%;
  top: 0;
}

.info-commit {
  left: -100%;
}

.info h2 {
  text-align: center
}

.product-image:hover .info {
  transform: translateX(0);
}

.info ul li {
  transition: .3s ease;
  line-height: 1;
  padding-bottom: 7px;
}

.info ul li:hover {
  transform: translateX(calc(20px* var(--expansion-factor))) scale(1.15);
}

.product-image:hover img {
  transition: all 0.3s ease-out;
}

.product-image:hover img {
  transform: scale(1.2, 1.2);
}

.container #tagCloud {
  position: relative;
  height: 60px;
  display: inline-block;
  overflow-y: hidden;
  padding-left: 30px;
  width: calc(100% - 40px);
  cursor: default;
}

.container #tagCloud:hover {
  height: 250px;
  transition: all 1s ease-out;
}

.container #tagCloud label {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.card-cloud {
  text-align: center;
}

.card-cloud span.tag-cloud-tag:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 1000px) {
  .container {
    display: block;
    margin: 5em auto;
  }
}